@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

*{
    margin: 0;
    padding: 0;
}

/*body {*/
/*    scroll-behavior: smooth;*/
/*}*/
.overflow_hidden {
    overflow: hidden!important;
}

.main_wrapper {
    background-image: url('../../assets/img/back_img.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
}

.main{
    width: 100%;
}


.select_account {
    width: 100%;
    max-width: 1303px;
    margin: 0 auto;
    padding-top: 70px;
    margin-bottom: 90px
}
.show_select_account_popup_wrapper {
    position: relative;
    width: 100%;
    max-width: 1303px;
    margin: 0 auto;
    background-image: url("../img/select_post_back_img.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 74px;
    padding-bottom: 42px;
    padding-left: 70px;
    padding-right: 70px;
    border-radius: 30px;
    box-shadow: 8px 4px 78px rgba(0, 0, 0, 0.25);
    border: 1px solid #CEC7C7;
}
.show_select_account_popup_wrapper2 {
    position: relative;
    width: 100%;
    max-width: 652px;
    margin: 0 auto;
    background-image: url("../img/select_post_back_img.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 74px;
    padding-bottom: 78px;
    padding-left: 70px;
    padding-right: 70px;
    border-radius: 30px;
    box-shadow: 8px 4px 78px rgba(0, 0, 0, 0.25);
    border: 1px solid #CEC7C7;
}
.show_select_account_popup_input_field_icon {
    /* height: 11px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.show_select_account_popup_title {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 65px;
}
.show_select_account_popup_input_field_wrapper {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E4E4E7;
    border-radius: 9px;
    height: 65px;
    margin-bottom: 21px;
    padding: 15px;
    display: flex;
    /*align-items: center;*/

}


.show_select_account_popup_textarea_field {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #52525B;
    width: 100%;
    border: none;
    background: none;
    height: 100%;
    resize: none;
    cursor: pointer;
    outline: none;

}

.show_select_account_popup_input_field {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #090A0A;
    height: 100%;
    border: none;
    cursor: pointer;
    outline: none;
    width: 75%;
}
.show_select_account_popup_input_field::placeholder {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #000000;
}

.show_select_account_popup_continue_btn {
    width: 100%;
    max-width: 472px;
    margin: 0 auto;
    margin-top: 40px;
    background: #2563EB;
    border-radius: 9px;
    height: 65px;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.2px;
    color: #FFFFFF;

}
.show_select_account_popup_close_btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: 1px #6DDCFF solid;
    border-radius: 60px;
    width: 46px;
    height: 36px;
    position: absolute;
    right: 45px;
    top: 25px;
}
.show_select_account_popup_input_field_icon2 {
    margin-right: 10px;
    height: 33px;
}

@media (min-width: 1025px) and (max-width: 1170px) {
    .show_select_account_popup_wrapper {
        padding-left: 50px!important;
        padding-right: 50px!important;
    }

    .show_select_account_popup_wrapper2 {
        padding-left: 50px!important;
        padding-right: 50px!important;
    }
    .select_posts_item {
        height: 145px!important;
    }
    .select_posts_form_wrapper {
        margin-right: 45px!important;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .show_select_account_popup_wrapper {
        padding-left: 40px!important;
        padding-right: 40px!important;
    }

    .show_select_account_popup_wrapper2 {
        padding-left: 40px!important;
        padding-right: 40px!important;
    }
    .select_posts_item {
        height: 125px!important;
    }
    .select_posts_form_wrapper {
        margin-right: 35px!important;
    }
}

@media (max-width: 768px) {
    .select_pay_item {
        width: 100% !important
    }
    .show_select_account_popup_continue_btn {
        max-width: unset!important;
    }
    .show_select_pay_popup_continue_btn {
        max-width: unset!important;
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .select_posts_form_select_posts_items_wrapper {
        margin-bottom: 34px!important;
    }
    .select_posts_form_wrapper {
        margin-bottom: 31px!important;
    }
    .show_select_account_popup_wrapper {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 40px!important;
        padding-right: 40px!important;
    }

    .show_select_account_popup_wrapper2 {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 40px!important;
        padding-right: 40px!important;
    }
    .select_posts_item {
        height: 120px!important;
    }
    .select_posts_form_wrapper {
        margin-right: unset!important;
        max-width: unset!important;
        margin-bottom: 62px;
    }
    .select_posts_form_select_posts_items_wrapper {
        flex-direction: column;
    }


    .show_select_pay_popup_input_field {
        height: 54px!important;
    }
    .select_pay_item {
        padding-left: 35px!important;
    }
    .select_pay_item_icon1 img {
        width: 23px!important;
    }
    .select_pay_item_icon2 img {
        width: 23px!important;
    }
    .select_pay_item {
        height: 54px!important;
    }

    .show_select_account_popup_title {
        font-size: 45px!important;
        margin-bottom: 50px!important;
    }
    .show_select_account_popup_close_btn {
        width: 35px!important;
        height: 26px!important;
        position: absolute;
        right: 20px!important;
        top: 20px!important;
    }
    .show_select_account_popup_close_btn img {
        width: 12px!important;
        height: 12px!important;
    }
}
@media (min-width: 426px) and  (max-width: 480px) {
    .select_posts_form_select_posts_items_wrapper {
        margin-bottom: 24px!important;
    }
    .select_posts_form_wrapper {
        margin-bottom: 21px!important;
    }
    .select_posts_item {
        height: 100px!important;
    }
    .select_posts_form_wrapper {
        margin-right: unset!important;
        max-width: unset!important;
        margin-bottom: 41px;
    }
    .select_posts_form_select_posts_items_wrapper {
        flex-direction: column;
    }

    .show_select_pay_popup_input_field {
        height: 54px!important;
    }
    .select_pay_item {
        padding-left: 25px!important;
    }
    .select_pay_item_icon1 img {
        width: 20px!important;
    }
    .select_pay_item_icon1 {
        margin-right: 10px!important;
    }
    .select_pay_item_icon2 img {
        width: 23px!important;
    }
    .select_pay_item {
        height: 44px!important;
    }
    .show_select_account_popup_wrapper {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 30px!important;
        padding-right: 30px!important;
    }
    .show_select_account_popup_wrapper2 {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 30px!important;
        padding-right: 30px!important;
    }


    .show_select_account_popup_title {
        font-size: 40px!important;
        margin-bottom: 33px!important;
    }
    .show_select_account_popup_close_btn {
        width: 35px!important;
        height: 26px!important;
        position: absolute;
        right: 20px!important;
        top: 20px!important;
    }
    .show_select_account_popup_close_btn img {
        width: 12px!important;
        height: 12px!important;
    }
    .show_select_account_popup_input_field_wrapper {
        height: 60px!important;
    }
    .show_select_account_popup_continue_btn {
        height: 60px!important;
    }
}
@media  (min-width: 351px) and (max-width: 425px) {
    .select_posts_form_select_posts_items_wrapper {
        margin-bottom: 18px!important;
    }
    .select_posts_form_wrapper {
        margin-bottom: 11px!important;
    }
    .select_posts_item {
        height: 90px!important;
    }
    .select_posts_form_wrapper {
        margin-right: unset!important;
        max-width: unset!important;
        margin-bottom: 62px;
    }
    .select_posts_form_select_posts_items_wrapper {
        flex-direction: column;
    }

    .show_select_pay_popup_input_field {
        height: 54px!important;
    }
    .select_pay_item {
        padding-left: 25px!important;
    }
    .select_pay_item_icon1 {
        margin-right: 10px!important;
    }
    .select_pay_item_icon2 img {
        width: 23px!important;
    }
    .select_pay_item_icon1 img {
        width: 18px!important;
    }
    .select_pay_item {
        height: 44px!important;
    }
    .show_select_account_popup_input_field_icon img {
        width: 37px!important;
    }
    .show_select_account_popup{
        padding-top: 50px!important;
        padding-bottom: 50px!important;
    }
    .show_select_account_popup_wrapper {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
    .show_select_account_popup_wrapper2 {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 20px!important;
        padding-right: 20px!important;
    }

    .show_select_account_popup_title {
        font-size: 40px!important;
        margin-bottom: 23px!important;
        text-align: left!important;
    }
    .show_select_account_popup_close_btn {
        width: 35px!important;
        height: 26px!important;
        position: absolute;
        right: 20px!important;
        top: 20px!important;
    }
    .show_select_account_popup_close_btn img {
        width: 12px!important;
        height: 12px!important;
    }
    .show_select_account_popup_input_field_wrapper {
        height: 55px!important;
    }
    .show_select_account_popup_continue_btn {
        height: 55px!important;
    }
    .show_select_account_popup_input_field_icon2 img {
        width: 25px!important;
    }
    .show_select_account_popup_input_field::placeholder {
        font-size: 15px!important;
    }
    .show_select_account_popup_textarea_field {
        font-size: 15px!important;
    }
    .show_select_account_popup_input_field {
        font-size: 15px!important;
    }

}
@media   (max-width: 350px) {
    .select_posts_form_select_posts_items_wrapper {
        margin-bottom: 18px!important;
    }
    .select_posts_form_wrapper {
        margin-bottom: 11px!important;
    }
    .select_posts_item {
        height: 78px!important;
    }
    .select_posts_form_wrapper {
        margin-right: unset!important;
        max-width: unset!important;
        margin-bottom: 62px;
    }
    .select_posts_form_select_posts_items_wrapper {
        flex-direction: column;
    }

    .show_select_pay_popup_input_field {
        height: 54px!important;
    }
    .select_pay_item {
        padding-left: 25px!important;
    }
    .select_pay_item_icon1 {
        margin-right: 10px!important;
    }
    .select_pay_item_icon2 img {
        width: 23px!important;
    }
    .select_pay_item_icon1 img {
        width: 18px!important;
    }
    .select_pay_item {
        height: 44px!important;
    }
    .show_select_account_popup_input_field_icon img {
        width: 37px!important;
    }
    .show_select_account_popup{
        padding-top: 50px!important;
        padding-bottom: 50px!important;
    }
    .show_select_account_popup_wrapper {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
    .show_select_account_popup_wrapper2 {
        max-width: unset!important;
        width: 100% !important;
        padding-left: 20px!important;
        padding-right: 20px!important;
    }

    .show_select_account_popup_title {
        font-size: 40px!important;
        margin-bottom: 23px!important;
        text-align: left!important;
    }
    .show_select_account_popup_close_btn {
        width: 35px!important;
        height: 26px!important;
        position: absolute;
        right: 20px!important;
        top: 20px!important;
    }
    .show_select_account_popup_close_btn img {
        width: 12px!important;
        height: 12px!important;
    }
    .show_select_account_popup_input_field_wrapper {
        height: 55px!important;
    }
    .show_select_account_popup_continue_btn {
        height: 55px!important;
    }
    .show_select_account_popup_input_field_icon2 img {
        width: 25px!important;
    }
    .show_select_account_popup_input_field::placeholder {
        font-size: 15px!important;
    }
    .show_select_account_popup_textarea_field {
        font-size: 15px!important;
    }
    .show_select_account_popup_input_field {
        font-size: 15px!important;
    }

}

.select_posts_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /*max-width: 633px;*/
    margin-bottom: 27px
}
.select_posts_items_wrapper2 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /*max-width: 633px;*/
    margin-bottom: 27px
}
.select_posts_item {
    width: 31%;
    height: 170px;
    margin-bottom: 13px;
    border: none;
    background: none;
    cursor: pointer;
    /*border: 4px solid #485AFF;*/
    /*border: 4px solid transparent;*/
    border-radius: 13px;

}
.select_posts_item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 13px;
    overflow: hidden;
}

@media (min-width: 476px) and (max-width: 768px) {
    .show_select_account_popup_wrapper {
        padding-bottom: 70px!important;
    }
    .show_select_account_popup_wrapper2 {
        padding-bottom: 70px!important;
    }

    .select_posts_item {
        margin-bottom: 10px!important;
    }
    .select_img {
        height: 420px!important;
    }
}
@media (min-width: 426px) and (max-width: 475px) {
    .select_img {
        height: 380px!important;
        /*margin-bottom: 23px!important;*/
    }
    .select_posts_item {
        margin-bottom: 8px!important;
    }
    .show_select_account_popup_wrapper {
        padding-bottom: 50px!important;
    }
    .show_select_account_popup_wrapper2 {
        padding-bottom: 50px!important;
    }
    /*.select_posts_item {*/
    /*    height: 94px!important;*/
    /*}*/
}
@media  (max-width: 425px) {
    .select_img {
        height: unset!important;
        /*margin-bottom: 13px!important;*/
    }
    .show_select_account_popup_continue_btn {
        margin-top: 30px!important;
    }
    .select_posts_item {
        margin-bottom: 8px!important;
    }
    .show_select_account_popup_wrapper {
        padding-bottom: 50px!important;
    }
    .show_select_account_popup_wrapper2 {
        padding-bottom: 50px!important;
    }
}

.select_themes_item {
    /*width: 74px;*/
    /*height: 28px;*/
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: 1px solid #6DDCFF;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 9px;
    background: none;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-left: 2px;
    margin-right: 2px;

}
.select_img {
    width: 100%;
    max-width: 472px;
    height: 472px;
    margin: 0 auto;
    /*margin-bottom: 33px;*/
}
.select_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 13px;
    overflow: hidden;
}
.select_pay_item {
    /*max-width: 472px;*/
    width: 48%;
    height: 64px;
    border-radius: 60px;
    /*margin: 0 auto;*/
    margin-bottom: 17px;
    border: 1px solid #6DDCFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    cursor: pointer;
    outline: none;
    padding-left: 47px;
    padding-right: 15px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;

}
.select_pay_item_icons_wrapper {
    display: flex;
    align-items: center;
}
.show_select_pay_popup_continue_btn {
    max-width: 472px;
    width: 100%;
    margin: 0 auto;
    background: none;
    border-radius: 9px;
    height: 65px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 17px;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.2px;
    color: #FFFFFF;
    border: 2px solid #2563EB;
    /*border-radius: 9px;*/
}

.show_select_pay_popup_input_field {
    width: 100%;
    cursor: pointer;
    outline: none;
    background: #FFFFFF;
    border-radius: 100px;
    height: 64px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: -0.24px;
    color: #000000;
    padding-left: 40px
}

.show_select_pay_popup_input_field_parent {
    width: 100%
}
.show_select_pay_popup_input_field::placeholder {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: -0.24px;
    color: #000000;
}

.select_pay_item_icon1 {
    margin-right: 15px;
}


@media (min-width: 426px) and (max-width: 1440px) {
    .select_account {
        max-width: unset!important;
        padding-left: 45px!important;
        padding-right: 45px!important;
    }
}


@media (max-width: 425px) {
    .select_account {
        max-width: unset!important;
        padding-left: 25px!important;
        padding-right: 25px!important;
    }
}

.select_posts_form_select_posts_items_wrapper {
    display: flex;
    margin-bottom: 44px;
    width: 100%;
    justify-content: space-between;
}

.select_posts_form_wrapper {
    width: 100%;
    max-width: 472px;
    margin-right: 64px;
}

.select_pay_items_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

