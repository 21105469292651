@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

*{
    margin: 0;
    padding:0;
}

.header{
    width: 100%;
    padding-top: 15px;

}

.header_wrapper {
    width: 100%;
    max-width: 1303px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_links_wrapper {
    width: 100%;
    max-width: 771px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_logo_link{
    display: flex;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

.header_link{
    transition: all 2s;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
}

.login_btn {
    width: 155px;
    height: 50px;
    border: 1px #6DDCFF solid;
    border-radius: 60px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
    background: none;
    cursor: pointer;

}

.logged_user_img_info_box {
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 60px;
    margin-bottom: 23px;
}

.logged_user_avatar{
    width: 40px;
    height: 40px;
    position: relative;
    margin-right: 12px;
}
.logged_user_avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 100%;
}
.logged_user_info_box_name {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
}
.logged_user_info_box_info {
    font-weight: 400;
    font-size: 14px;
    color: #667085;
    font-family: 'Inter', sans-serif;
}
.online_user{
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #12B76A;
    border-radius: 100%;
    border: 2px solid #ffffff;
    z-index: 9;
    right: 4px;
    bottom: 0px;
}
.hamburger_menu_btn {
    display: none;
}
/*.header_mobile_wrapper {*/
/*    display: none;*/
/*}*/

@media (max-width: 1440px) and (min-width: 1025px) {
    .header_wrapper{
        max-width: unset!important;
        width: 100% !important;
        padding-left: 45px;
        padding-right: 45px;
    }
    .header_links_wrapper{
        max-width: 600px!important;
    }
}

@media (min-width: 381px) and (max-width: 1024px) {
    .header_wrapper{
        max-width: unset!important;
        width: 100% !important;
        padding-left: 45px;
        padding-right: 45px;
    }
}
@media  (max-width: 380px) {
    .header_wrapper{
        max-width: unset!important;
        width: 100% !important;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width: 1024px) and (min-width: 871px) {
    /*.header {*/
    /*    margin-bottom: 95px!important;*/
    /*}*/
    .header_wrapper{
        max-width: unset!important;
        width: 100% !important;
        padding-left: 45px;
        padding-right: 45px;
    }
    .header_links_wrapper{
        max-width: 500px!important;
    }
    .header_logo_link img {
        width: 90px!important;
    }
    .login_btn {
        width: 130px!important;
        height: 40px!important;
        border-radius: 40px!important;
    }

}
@media (min-width: 769px) and (max-width: 870px)  {
    /*.header {*/
    /*    margin-bottom: 90px!important;*/
    /*}*/
    .header_links_wrapper{
        max-width: 400px!important;
    }
    .header_logo_link img {
        width: 80px!important;
    }
    .header_link{
        font-size: 16px!important;
    }
    .login_btn {
        width: 130px!important;
        height: 40px!important;
        border-radius: 40px!important;
    }

}
@media  (max-width: 768px)  {
    /*.header {*/
    /*    margin-bottom: 88px!important;*/
    /*}*/
    .header_links_wrapper{
       display: none!important;
    }
    .login_btn{
        display: none!important;
    }
    .header_logo_link img {
        width: 77px!important;
        height: 36px!important;
    }
    .header_link{
        font-size: 16px!important;
    }
    .header_mobile_wrapper {
        /*display: none;*/
        position: fixed; /* Stay in place */
        z-index: 9999; /* Sit on top */
        left: 0;
        /*right: -1000px;*/
        /*transition: 0.5s;*/
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background:  rgba(23, 23, 23, 0.85);
    }
    .header_mobile_wrapper_child {
        background-image: url("../img/mobile_header_back_img.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 242px;
        position: absolute;
        z-index: 99;
        right: 0;
        top: 0;
        padding-top: 25px;
        padding-left: 10px;
        overflow: scroll;
        padding-right: 10px;

    }
    .hamburger_menu_btn {
        display: flex!important;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: none;
        border-radius: 60px;
        width: 46px;
        height: 36px;
        border: 1px #6DDCFF solid;
    }

    .header_mobile_wrapper_close_btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: 1px #6DDCFF solid;
        border-radius: 60px;
        width: 46px;
        height: 36px;
        position: absolute;
        right: 45px;
        top: 25px;
    }
    .header_link {
        display: block;
        padding-bottom: 23px;
    }
    .mobile_header_links_wrapper {
        padding-left: 30px;
        margin-bottom: 43px;

    }
    .mobile_login_btn_parent {
        padding-top: 60px;
    }
    .mobile_login_btn {
        width: 188px;
        height: 41px;
        border: 1px #6DDCFF solid;
        border-radius: 60px;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        font-family: 'Inter', sans-serif;
        background: none;
        cursor: pointer;
        margin-bottom: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .header_mobile_line {
        background: rgba(217, 217, 217, 0.3);
        border-radius: 100px;
        width: 201px;
        height: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
}

/*login popup*/
.checkbox_input_field {
    /*display: none;*/
    cursor: pointer;
    position: absolute;
    opacity: 0;
}

.checkbox_label {
    position: relative;
    display: flex;
    padding-left: 10px;
}

.checkMark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    border: 1px solid #94A3B8;
    border-radius: 5px;
}


.checkbox_input_field:checked ~ .checkMark {
    background-color: #2563EB;
    border: 1px solid #2563EB;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkMark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.checkbox_input_field:checked ~ .checkMark::after  {
    display: block;
}

/* Style the indicator (dot/circle) */
.checkMark::after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    left: 9px;
    top: 6px;
    width: 3px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-3px, -5px) rotate(45deg);
}
.login_popup {
    /*display: none;*/
    position: fixed; /* Stay in place */
    z-index: 999999; /* Sit on top */
    left: 0;
    /*right: -1000px;*/
    /*transition: 0.5s;*/
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background:  rgba(0, 0, 0, 0.7);
    padding-top: 50px;
    padding-bottom: 50px;
}
.login_popup_wrapper {
    position: relative;
    width: 100%;
    max-width: 693px;
    margin: 0 auto;
    background-image: url("../img/login_popup_img.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 70px;
    padding-bottom: 104px;
    padding-left: 84px;
    padding-right: 84px;
    border-radius: 30px;
    box-shadow: 8px 4px 78px rgba(0, 0, 0, 0.25);
    border: 1px solid #CEC7C7;
}
.login_popup_title {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 90px
}

.login_popup_input_field_title_wrapper {
    width: 100%;
    margin-bottom: 16px;
}
.login_popup_input_title {
    margin-bottom: 11px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}
.login_popup_input_field {
    background: #F8FAFC;
    border: 1px solid #CBD5E1;
    border-radius: 12px;
    width: 100%;
    height: 60px;
    padding-right: 12px;
    padding-left: 12px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #A1A1AA;
    outline: none;
    cursor: pointer;

}

.login_popup_input_field::placeholder {
    display: none;
}
.checkbox_forget_password_btns_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
    width: 100%;
}


.checkbox_input_label_title {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    padding-left: 18px;
}

.forget_password_btn {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2563EB;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
}

.login_sign_up_btns_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login_popup_btn {
    background: #2563EB;
    border-radius: 9px;
    width: 160px;
    height: 55px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #FFFFFF;
    border: none;
    text-decoration: none;
}

.sign_up_popup_btn {
    background: none;
    /*border-radius: 9px;*/
    /*width: 160px;*/
    /*height: 55px;*/
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #2563EB;
    border: none;
}
.main_sign_up_popup_btn {
    background: #2563EB;
    border-radius: 9px;
    width: 160px;
    height: 55px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #FFFFFF;
    border: none;
    margin-top: 27px;
}

.login_popup_close_btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: 1px #6DDCFF solid;
    border-radius: 60px;
    width: 46px;
    height: 36px;
    position: absolute;
    right: 45px;
    top: 25px;
}

@media (min-width: 971px ) and (max-width: 1070px) {
    .login_popup_wrapper {
        padding-bottom: 80px!important;
        padding-left: 50px!important;
        padding-right: 50px!important;
    }
    .login_popup_title {
        margin-bottom: 80px!important;
    }
}
@media (min-width: 769px ) and (max-width: 970px) {
    .login_popup_wrapper {
        padding-bottom: 60px!important;
        padding-left: 35px!important;
        padding-right: 35px!important;
    }
    .login_popup_title {
        margin-bottom: 70px!important;
    }
}
@media (min-width: 426px) and (max-width: 768px) {
    .login_popup_wrapper {
        padding-bottom: 60px!important;
        padding-left: 30px!important;
        padding-right: 30px!important;
        max-width: unset;
        width: 90% !important;
    }
    .login_popup_title {
        margin-bottom: 65px!important;
    }
}
@media (min-width: 376px) and (max-width: 425px) {
    .login_popup_wrapper {
        padding-bottom: 49px!important;
        padding-left: 20px!important;
        padding-right: 20px!important;
        padding-top: 50px!important;
        max-width: unset;
        width: 90% !important;
    }
    .login_popup_title {
        margin-bottom: 65px!important;
    }
    .login_sign_up_btns_wrapper {
        flex-direction: column;
    }
    .login_popup_btn {
        width: 100%!important;
        margin-bottom: 10px;
    }
    .sign_up_popup_btn {
        width: 100%!important;
    }
    .main_sign_up_popup_btn {
        width: 100%!important;
        margin-top: 27px!important;
        margin: 0 auto;
    }
    .login_popup_close_btn {
        right: 15px!important;
        top: 20px!important;
        width: 36px!important;
        height: 30px!important;
    }
    .login_popup_close_btn img {
        width: 13px!important;
        height: 13px!important;
    }


}
@media (max-width: 375px) {
    .main_sign_up_popup_btn {
        width: 100%!important;
        margin-top: 27px!important;
        margin: 0 auto;
    }
    .login_popup_wrapper {
        padding-bottom: 49px!important;
        padding-left: 20px!important;
        padding-right: 20px!important;
        padding-top: 50px!important;
        max-width: unset;
        width: 90% !important;
    }
    .login_popup_title {
        font-size: 40px!important;
        margin-bottom: 50px!important;
    }
    .login_popup_close_btn {
        right: 15px!important;
        top: 20px!important;
        width: 36px!important;
        height: 30px!important;
    }
    .login_popup_close_btn img {
        width: 13px!important;
        height: 13px!important;
    }

    .login_sign_up_btns_wrapper {
        flex-direction: column;
    }
    .login_popup_btn {
        width: 100%!important;
        margin-bottom: 10px;
    }
    .sign_up_popup_btn {
        width: 100%!important;
    }

    .checkbox_input_label_title {
        font-size: 12px!important;
    }
    .forget_password_btn {
        font-size: 12px!important;
    }
    .login_popup_input_field {
        height: 45px!important;
        border-radius: 6px!important;
    }
    .login_popup_wrapper {
        border-radius: 20px!important;
    }
}

.login_popup_input_field_title_checkbox_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
}

/*login popup end*/
