@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

*{
    margin: 0;
    padding: 0;
}

.overflow_hidden {
    overflow: hidden!important;
}

.main_wrapper {
    background-image: url('../../assets/img/back_img.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
}

.main{
    width: 100%;
}


/*profile start*/
.profile {
    width: 100%;
    margin-bottom: 200px;
    margin-top: 70px;
}
.profile_wrapper {
    width: 100%;
    max-width: 1303px;
    margin: 0 auto;
}
.hidden_div {
    height: 52px;
    width: 128px;
}

.profile_change_password_email_pay_history_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 39px;
}
.profile_change_password_email_item {
    background-image: url("../img/profile_back_img1.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 62px;
    padding-bottom: 62px;
    padding-right: 30px;
    padding-left: 30px;
    max-width: 639px;
    width: 100%;
    border: 1px solid #CEC7C7;
    border-radius: 30px;
}
.profile_change_password_email_item_input_field_btn_box {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    justify-content: space-between;
}
.profile_change_password_email_item_input_field {
    background: #FFFFFF;
    border-radius: 13px;
    padding: 16px;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: -0.24px;
    color: #000000;
    cursor: pointer;
    outline: none;
    width: 100%;
    max-width: 440px;
    margin-right: 9px;
}
.profile_change_password_email_item_input_field::placeholder {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.24px;
    color: #000000;
}
.profile_change_password_email_item_input_field_btn {
    width: 128px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2563EB;
    border-radius: 13px;
    height: 52px;
    border: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.2px;
    color: #FFFFFF;
}
.profile_change_password_email_item_input_field_parent {
    width: 100%;
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile_change_password_pay_history_item {
    background-image: url("../img/profile_back_img2.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 28px;
    padding-left: 28px;
    max-width: 639px;
    width: 100%;
    border: 1px solid #CEC7C7;
    border-radius: 30px;
}
.profile_pay_history_item_title_pay_btn_box {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 17px;
}
.profile_pay_history_item_title {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #FFFFFF;
    margin-right: 37px;
}
.profile_pay_history_item_pay_btn {
    width: 128px;
    height: 52px;
    background: #2563EB;
    border-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.2px;
    color: #FFFFFF;
    border: none;
}
.profile_pay_history_details_item_titles_wrapper {
    display: flex;
    margin-bottom: 29px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.profile_pay_history_details_item_title_parent {
    border: 1px solid #7F60F9;
    width: 23%;
    height: 52px;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile_pay_history_details_item_title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.2px;
    color: #FFFFFF;
}
.profile_pay_history_details_item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.profile_pay_history_details_item_info {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
}
.profile_pay_history_details_item_info_box {
    width: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
}

 .p-datatable-responsive-scroll {
     background-image: url("../img/data_table_back_img.png") !important;
     background-size: cover;
     background-repeat: no-repeat;
     width: 100%;
     border: 1px solid #CEC7C7;
     border-radius: 30px;
     padding-top: 35px;
     padding-bottom: 52px;
     padding-left: 30px;
     padding-right: 30px;
 }

.p-datatable .p-datatable-thead > tr > th {
    /*text-align: unset!important;*/
    padding: 0  20px 10px 20px !important;
    /*border: 1px solid #7F60F9!important;*/
    border: unset!important;
    /*font-family: 'Poppins',sans-serif;*/
    /*font-style: normal;*/
    /*font-weight: 500!important;*/
    /*font-size: 16px;*/
    /*letter-spacing: -0.2px;*/
    /*color: #FFFFFF!important;*/
    background: unset!important;
    /*transition: box-shadow 0.2s;*/
    /*width: 184px;*/
    /*height: 52px;*/
    /*border-radius: 100px;*/
}
.p-datatable .p-column-header-content > span {
    display: flex;
    justify-content: center;
    width: 100%;
}
.p-paginator .p-paginator-first svg path {
    fill: #ffffff!important;
}
.p-paginator .p-paginator-prev svg path {
    fill: #ffffff!important;
}
.p-paginator .p-paginator-next svg path {
    fill: #ffffff!important;
}
.p-paginator .p-paginator-last svg path {
    fill: #ffffff!important;
}

.p-datatable .p-column-header-content {
    display: flex;
    align-items: center;
    text-align: unset!important;
    padding: unset!important;
    border: 1px solid #7F60F9!important;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 500!important;
    font-size: 16px;
    letter-spacing: -0.2px;
    color: #FFFFFF!important;
    background: unset!important;
    transition: box-shadow 0.2s;
     max-width: 184px;
    width: 100%;
    height: 52px;
    border-radius: 100px;
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: center!important;
    border: unset!important;
     border-width: 0!important;
    /* text-align: center; */
    /* padding: 0!important;*/
    /* display: flex; */
    /* justify-content: center; */
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
}
.p-datatable .p-datatable-tbody > tr {
    background: unset!important;
     transition: box-shadow 0.2s;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF!important;
    border-bottom: unset!important;
    /* justify-content: space-between; */
    width: 100%;
    padding: 8px!important;
}
.p-datatable>.p-datatable-wrapper {
    overflow: auto;
    margin-bottom: 62px!important;
}
.p-paginator {
     background: unset!important;
    color: #ffffff!important;
    /* border: solid #e9ecef; */
    border-width: 0!important;
    padding: 0.5rem 1rem;
    border-radius: 0!important;

}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-color: unset!important;
    width: 40px!important;
    height: 40px!important;
    background: rgba(249, 245, 255, 0.1) !important;
    border-radius: 8px!important;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #485AFF!important;
    margin-left: 10px;
}
.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
    display: none!important;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: rgba(249, 245, 255, 0.1) !important;
    border-color: transparent!important;
    color: #485AFF!important;
    margin-left: 10px;
}
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover {
    background: rgba(249, 245, 255, 0.1) !important;
    border-color: transparent!important;
}
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover   svg path{

    fill: #485AFF!important;

}
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover svg path {
    fill: #485AFF!important;
}
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: rgba(249, 245, 255, 0.1) !important;
    border-color: transparent!important;
}
.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: unset!important;
    margin: unset!important;
    transition: box-shadow 0.2s;
    border-color: unset!important;
    width: 40px!important;
    height: 40px!important;
    background: unset!important;
    border-radius: 8px!important;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff!important;
}
.p-paginator .p-paginator-next {
    min-width: unset!important;
    margin: unset!important;
    transition: box-shadow 0.2s;
    border-color: unset!important;
    width: 40px!important;
    height: 40px!important;
    /*background: unset!important;*/
    border-radius: 8px!important;
}
.p-paginator .p-paginator-last {
    min-width: unset!important;
    margin: unset!important;
    transition: box-shadow 0.2s;
    border-color: unset!important;
    width: 40px!important;
    height: 40px!important;
    /*background: unset!important;*/
    border-radius: 8px!important;
}
.new_order_btn {
    background: #2563EB;
    border-radius: 9px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    max-width: 472px;
    margin: 0 auto;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.2px;
    color: #FFFFFF;
    border: none;
}
.datatable_wrapper {
    margin-bottom: 65px;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    width: 40px!important;
    height: 40px!important;
}

@media (min-width: 1025px) and  (max-width: 1440px) {
    .profile_wrapper {
        max-width: unset!important;
        padding-left: 35px;
        padding-right: 35px;
    }
    .profile {
        margin-bottom: 150px!important;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .profile_wrapper {
        max-width: unset!important;
        padding-left: 30px;
        padding-right: 30px;
    }
    .profile {
        margin-bottom: 120px!important;
    }
    .profile_change_password_email_item {
        padding-top: 52px!important;
        padding-bottom: 52px!important;
        padding-right: 26px!important;
        padding-left: 26px!important;
    }
    .profile_change_password_pay_history_item {
        padding-top: 42px!important;
        padding-bottom: 42px!important;
        padding-right: 26px!important;
        padding-left: 26px!important;
    }
    .profile_change_password_email_pay_history_items_wrapper {
        margin-bottom: 30px!important;
    }
    .profile_pay_history_details_item_title {
        font-size: 14px!important;
    }
    .profile_pay_history_details_item_info {
        font-size: 16px!important;
    }
}
@media (min-width: 426px)  and (max-width: 768px) {
    .datatable_wrapper {
        margin-bottom: 55px!important;
    }

    .p-datatable-responsive-scroll {
        padding-left: 20px!important;
        padding-right: 20px!important;
        padding-bottom: 42px!important;
    }
    .profile_pay_history_details_item_titles_wrapper {
        margin-bottom: 20px!important;
    }
    .profile_pay_history_details_item_title {
        font-size: 12px!important;
    }
    .profile_pay_history_details_item_title_parent {
        height: 40px!important;
    }
    .profile_pay_history_details_item_info {
        font-size: 14px!important;
    }
    .profile_change_password_email_item_input_field_btn {
        font-size: 14px!important;
    }
    .profile_change_password_email_item {
        padding-top: 32px!important;
        padding-bottom: 32px!important;
        padding-right: 22px!important;
        padding-left: 22px!important;
    }
    .profile_change_password_pay_history_item {
        padding-top: 32px!important;
        padding-bottom: 32px!important;
        padding-right: 22px!important;
        padding-left: 22px!important;
    }
    .profile_wrapper {
        max-width: unset!important;
        padding-left: 25px;
        padding-right: 25px;
    }
    .profile {
        margin-bottom: 100px!important;
    }
    .profile_change_password_email_pay_history_items_wrapper {
        margin-bottom: 22px!important;

    }

}
@media  (min-width: 381px) and (max-width: 425px) {
    .profile {
        margin-top: 60px!important;
    }
    .datatable_wrapper {
        margin-bottom: 50px!important;
    }
    .new_order_btn {
        height: 50px!important;
        font-size: 14px!important;
    }
    .p-datatable-responsive-scroll {
        padding-left: 20px!important;
        padding-right: 20px!important;
        padding-bottom: 30px!important;
    }
    .profile_pay_history_details_item_titles_wrapper {
        margin-bottom: 15px!important;
    }
    .profile_pay_history_details_item_title_parent {
        height: 35px!important;
    }
    .profile_pay_history_item_title_pay_btn_box {
        justify-content: space-between;
    }
    .profile_pay_history_item_pay_btn {
        width: 99px!important;
        height: 35px!important;
    }
    .profile_pay_history_details_item_info {
        font-size: 12px!important;
    }
    .profile_pay_history_details_item_title {
        font-size: 12px!important;
    }
    .profile_change_password_email_item_input_field_btn {
        font-size: 12px!important;
        height: 42px!important;
    }
    .hidden_div {
        height: 42px!important;
    }
    .profile_change_password_email_item_input_field {
        height: 42px!important;
    }
    .profile_change_password_email_item {
        padding-top: 22px!important;
        padding-bottom: 22px!important;
        padding-right: 20px!important;
        padding-left: 20px!important;
    }
    .profile_change_password_pay_history_item {
        padding-top: 22px!important;
        padding-bottom: 22px!important;
        padding-right: 20px!important;
        padding-left: 20px!important;
    }
    .profile_wrapper {
        max-width: unset!important;
        padding-left: 25px;
        padding-right: 25px;
    }
    .profile {
        margin-bottom: 60px!important;
    }
    .profile_change_password_email_pay_history_items_wrapper {
        margin-bottom: 14px!important;
    }
}
@media   (max-width: 380px) {
    .profile {
        margin-top: 60px!important;
    }
    .new_order_btn {
        height: 50px!important;
        font-size: 14px!important;
    }
    .datatable_wrapper {
        margin-bottom: 40px!important;
    }
    .profile_pay_history_item_title_pay_btn_box {
        justify-content: space-between;
    }
    .profile_pay_history_details_item_title_parent {
        height: 30px!important;
    }
    .profile_pay_history_item_pay_btn {
        width: 99px!important;
        height: 35px!important;
    }
    .profile_pay_history_details_item_titles_wrapper {
        margin-bottom: 15px!important;
    }
    .profile_pay_history_details_item_title {
        font-size: 10px!important;
    }
    .profile_pay_history_details_item_info {
        font-size: 12px!important;
    }
    .profile_change_password_pay_history_item {
        padding-top: 22px!important;
        padding-bottom: 22px!important;
        padding-right: 20px!important;
        padding-left: 20px!important;
    }
    .profile_change_password_email_item_input_field_btn {
        font-size: 12px!important;
        height: 42px!important;
    }
    .hidden_div {
        height: 42px!important;
    }
    .profile_change_password_email_item_input_field {
        height: 42px!important;
    }
    .profile_change_password_email_pay_history_items_wrapper {
        margin-bottom: 14px!important;
    }
    .profile_change_password_email_item {
        padding-top: 22px!important;
        padding-bottom: 22px!important;
        padding-right: 20px!important;
        padding-left: 20px!important;
    }
    .profile_wrapper {
        max-width: unset!important;
        padding-left: 25px;
        padding-right: 25px;
    }
    .profile {
        margin-bottom: 60px!important;
    }
}

@media (min-width: 769px) and (max-width: 1440px) {
    .profile_change_password_email_item {
        max-width: unset!important;
        width: 49% !important;
    }
    .profile_change_password_pay_history_item {
        max-width: unset!important;
        width: 49% !important;
    }
}
@media  (max-width: 768px) {
    .profile_change_password_email_item_input_field {
        max-width: unset!important;
    }
    .profile_change_password_email_item_input_field_btn_box {
        margin-bottom: 13px!important;
    }
    .profile_change_password_email_item_input_field_parent {
        margin-bottom: 13px!important;

    }
    .profile_change_password_email_pay_history_items_wrapper {
        flex-direction: column;
    }
    .profile_change_password_email_item {
        max-width: unset!important;
        width: 100%!important;
        margin-bottom: 14px;
    }
    .profile_change_password_pay_history_item {
        max-width: unset!important;
        width: 100%!important;
    }

}

@media (min-width: 769px) and (max-width: 920px) {
    .p-paginator {
        padding: 0!important;
    }
    .p-datatable-table {
        min-width: unset!important;
    }
    .p-datatable .p-datatable-thead > tr > th {
        padding: 0 5px 15px 5px!important;

    }
    .p-datatable>.p-datatable-wrapper {
        overflow: unset!important;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        padding: 0 0 10px 0!important;
    }
    .p-datatable .p-column-header-content > span {
        font-size: 13px!important;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        font-size: 16px!important;
    }
    .p-datatable .p-datatable-tbody > tr > td img {
        width: 45px!important;
        height: 45px!important;
        border-radius: 20px!important;
    }
    .p-datatable>.p-datatable-wrapper {
        margin-bottom: 42px!important;
    }
}
@media (min-width: 476px) and (max-width: 768px) {
    .p-paginator {
        padding: 0!important;
    }
    .p-datatable-table {
        min-width: unset!important;
    }
    .p-datatable .p-datatable-thead > tr > th {
        padding: 0 5px 15px 5px!important;

    }
    .p-datatable>.p-datatable-wrapper {
        overflow: unset!important;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        padding: 0 0 10px 0!important;
    }
    .p-datatable .p-column-header-content > span {
        font-size: 12px!important;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        font-size: 14px!important;
    }
    .p-datatable .p-datatable-tbody > tr > td img {
        width: 38px!important;
        height: 38px!important;
        border-radius: 15px!important;
    }
    .p-datatable>.p-datatable-wrapper {
        margin-bottom: 33px!important;
    }

}
@media (min-width: 351px) and  (max-width: 475px) {
    .p-datatable-table {
        min-width: unset!important;
    }
    .p-paginator {
        padding: 0!important;
    }
    .p-datatable .p-datatable-thead > tr > th {
        padding: 0 2px 10px 2px!important;

    }
    .p-datatable>.p-datatable-wrapper {
        overflow: unset!important;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        padding: 0 0 6px 0!important;
    }
    .p-datatable .p-column-header-content > span {
        font-size: 10px!important;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        font-size: 12px!important;
    }
    .p-datatable .p-datatable-tbody > tr > td img {
        width: 32px!important;
        height: 32px!important;
        border-radius: 10px!important;
    }
    .p-datatable>.p-datatable-wrapper {
        margin-bottom: 23px!important;
    }
    .p-datatable .p-column-header-content {
        height: 45px!important;
    }
    .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
        width: 38px!important;
        height: 38px!important;
    }
}

@media  (max-width: 351px) {
    .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
        width: 38px!important;
        height: 38px!important;
    }
    .p-datatable-table {
        min-width: unset!important;
    }
    .p-paginator {
        padding: 0!important;
    }
    .p-datatable .p-datatable-thead > tr > th {
        padding: 0 2px 10px 2px!important;
    }
    .p-datatable>.p-datatable-wrapper {
        overflow: unset!important;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        padding: 0 0 5px 0!important;
    }
    .p-datatable .p-column-header-content > span {
        font-size: 8px!important;
    }
    .p-datatable .p-datatable-tbody > tr > td {
        font-size: 8px!important;
    }
    .p-datatable .p-datatable-tbody > tr > td img {
        width: 25px!important;
        height: 25px!important;
        border-radius: 4px!important;
    }
    .p-datatable>.p-datatable-wrapper {
        margin-bottom: 20px!important;

    }
    .p-datatable .p-column-header-content {
        height: 45px!important;
    }
}

@media (max-width: 380px) {
    .p-datatable-responsive-scroll {
        padding-left: 16px!important;
        padding-right: 16px!important;
        padding-bottom: 30px!important;
    }
}

/*profile end*/

