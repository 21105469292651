@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

*{
    margin: 0;
    padding: 0;
}

.overflow_hidden {
    overflow: hidden!important;
}

.main_wrapper {
    background-image: url('../../assets/img/back_img.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
}

.main{
    width: 100%;
}


/*General Service Start*/
.general_service {
    width: 100%;
    margin-bottom: 200px;
    margin-top: 70px;

}
.general_service_wrapper {
    width: 100%;
    max-width: 1303px;
    margin: 0 auto;
}
.general_service_title {
    margin-bottom: 56px;
    font-family: 'IBM Plex Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    color: #FFFFFF;
}
.general_service_info {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 5px;

}
/*General Service End*/


@media (min-width: 381px) and (max-width: 1440px) {
    .general_service_wrapper {
        max-width: unset!important;
        padding-left: 45px;
        padding-right: 45px;
    }
}
@media  (max-width: 380px) {
    .general_service_wrapper {

        max-width: unset!important;
        padding-left: 30px;
        padding-right: 30px;
    }
    .general_service {
        margin-top: 50px!important;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .general_service {
        margin-bottom: 150px!important;
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    .general_service {
        margin-bottom: 100px!important;
    }
}


@media (min-width: 971px) and (max-width: 1070px) {
    .general_service_title {
        font-size: 70px!important;
    }
}

@media (min-width: 769px) and  (max-width: 970px) {
    .general_service_title {
        font-size: 60px!important;
        margin-bottom: 50px!important;
    }
}
@media  (min-width: 426px) and (max-width: 768px) {
    .general_service_title {
        font-size: 55px!important;
        margin-bottom: 45px!important;
        line-height: 55px!important;
    }
    .general_service {
        margin-bottom: 80px!important;
    }
}
@media   (max-width: 425px) {
    .general_service_title {
        font-size: 50px!important;
        margin-bottom: 40px!important;
        line-height: 50px!important;
    }
    .general_service {
        margin-bottom: 60px!important;
    }
}

