@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

*{
    margin: 0;
    padding: 0;
}

/*body {*/
/*    scroll-behavior: smooth;*/
/*}*/

.overflow_hidden {
    overflow: hidden!important;
}

.main_wrapper {
    background-image: url('../../assets/img/back_img.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
}

.main{
    width: 100%;
}

@media (max-width: 768px) {
    .main_wrapper {
        background-image:  url('../../assets/img/mobile_main_img.png')!important;
    }
}

/*instagram activity boost section start*/

.instagram_activity_boost {
    width: 100%;
    margin-top: 117px;
    margin-bottom: 47px;
}
.instagram_activity_boost_wrapper {
    width: 100%;
    max-width: 1303px;
    margin: 0 auto;
}



.instagram_activity_boost_title {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    color: #ffffff;
    margin-bottom: 60px;
    width: 100%;
    max-width: 603px;
    line-height: 90px;
}

.instagram_activity_boost_get_started_btn {
    margin-bottom: 61px;
    max-width: 310px;
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border-radius: 60px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #18181B;
    text-decoration: none;
}

.instagram_activity_boost_info_items_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 671px;
    width: 100%;
}
.instagram_activity_boost_info_item {
    width: 30%;
}
.instagram_activity_boost_info_item_img {
    margin-bottom: 23px;
    height: 64px;
    padding-left: 40px;
}
.instagram_activity_boost_info_item_img img {
    height: 64px;
}
.instagram_activity_boost_info_item:first-child .instagram_activity_boost_info_item_img  img{
    width: 64px;

}
.instagram_activity_boost_info_item:nth-child(2) .instagram_activity_boost_info_item_img  img{
    width: 69px;

}
.instagram_activity_boost_info_item:nth-child(3) .instagram_activity_boost_info_item_img  img{
    width: 49px;

}

.instagram_activity_boost_info_item_title {
    margin-bottom: 16px;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    letter-spacing: -0.5px;
    color: #FFFFFF;
    height: 60px;
    width: 182px;
}

.instagram_activity_boost_info_item_text {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #E5EAF1;
    max-width: 170px


}

.instagram_activity_boost_info_item_text_line_wrapper {
   position: relative;

}
.instagram_activity_boost_info_item_text_line {
    position: absolute;
    background-color:  #E5EAF1;
    height: 45px;
    width: 2px;
    right: 0;
    top: 0;
}

@media (min-width: 381px) and (max-width: 1440px) {
    .instagram_activity_boost_wrapper {
        max-width: unset!important;
        padding-left: 45px;
        padding-right: 45px;
    }
}
@media  (max-width: 380px) {
    .instagram_activity_boost_wrapper {
        max-width: unset!important;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width: 1025px) and  (max-width: 1290px) {
    .instagram_activity_boost_title {
        font-size: 75px!important;
    }
}
@media  (min-width: 983px) and (max-width: 1024px) {
    .instagram_activity_boost_title {
        font-size: 70px!important;
        max-width: 500px!important;
    }
    .instagram_activity_boost_info_item_title {
        font-size: 26px!important;
    }
    .instagram_activity_boost_get_started_btn {
        margin-bottom: 50px!important;
    }
}


@media (min-width: 769px) and (max-width: 983px) {
    .instagram_activity_boost_get_started_btn {
        margin-bottom: 40px!important;
    }
    .instagram_activity_boost_title {
        font-size: 60px!important;
        max-width: 450px!important;
        line-height: unset!important;
    }
    .instagram_activity_boost_info_item_title {
        font-size: 24px!important;
    }
}
@media  (min-width: 476px) and (max-width: 768px) {
    .instagram_activity_boost_get_started_btn {
        margin-bottom: 31px!important;
    }
    .instagram_activity_boost_info_item:first-child .instagram_activity_boost_info_item_img img {
        width: 49px!important;

    }
    .instagram_activity_boost_info_item:nth-child(2) .instagram_activity_boost_info_item_img img {
        width: 46px!important;

    }
    .instagram_activity_boost_info_item:nth-child(3) .instagram_activity_boost_info_item_img  img{
        width: 37px!important;

    }
    .instagram_activity_boost_info_item_img  {
        height: 49px!important;
        margin-bottom: 15px!important;
        padding-left: unset!important;
    }
    .instagram_activity_boost_title {
        font-size: 60px!important;
        max-width: 450px!important;
        line-height: unset!important;
    }
    .instagram_activity_boost_info_item_title {
        font-size: 22px!important;
        width: unset!important;
        margin-bottom: 11px!important;
        height: unset!important;
    }
    .instagram_activity_boost_info_item_text_line {
        display: none!important;
    }
    .instagram_activity_boost_info_item_text {
        max-width: unset!important;
    }
    .instagram_activity_boost_info_items_wrapper {
        flex-direction: column;
        max-width: unset!important;
    }
    .instagram_activity_boost_info_item {
        max-width: unset!important;
        margin-bottom: 24px;
        width: 100% !important;
    }

}
@media (min-width: 390px) and  (max-width: 475px) {
    .instagram_activity_boost_get_started_btn {
        margin-bottom: 31px!important;
    }
    .instagram_activity_boost_info_item:first-child .instagram_activity_boost_info_item_img img {
        width: 49px!important;

    }
    .instagram_activity_boost_info_item:nth-child(2) .instagram_activity_boost_info_item_img img {
        width: 46px!important;

    }
    .instagram_activity_boost_info_item:nth-child(3) .instagram_activity_boost_info_item_img  img{
        width: 37px!important;

    }
    .instagram_activity_boost_info_item_img  {
        height: 49px!important;
        margin-bottom: 15px!important;
        padding-left: unset!important;
    }
    .instagram_activity_boost_title {
        font-size: 50px!important;
        max-width: unset!important;
        line-height: unset!important;
        margin-bottom: 52px!important;
    }
    .instagram_activity_boost_title_btn_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .instagram_activity_boost_info_item_title {
        font-size: 20px!important;
        width: unset!important;
        margin-bottom: 11px!important;
        height: unset!important;
    }
    .instagram_activity_boost_get_started_btn {
        max-width: unset!important;
        font-size: 12px!important;
    }
    .instagram_activity_boost_info_item_text_line {
        display: none!important;
    }
    .instagram_activity_boost_info_item_text {
        max-width: unset!important;
    }
    .instagram_activity_boost_info_items_wrapper {
        flex-direction: column;
        max-width: unset!important;
    }
    .instagram_activity_boost_info_item {
        max-width: unset!important;
        margin-bottom: 24px;
        width: 100% !important;
    }

}
@media  (min-width: 331px) and (max-width: 390px) {
    .instagram_activity_boost_get_started_btn {
        margin-bottom: 31px!important;
    }
    .instagram_activity_boost_info_item:first-child .instagram_activity_boost_info_item_img img {
        width: 49px!important;

    }
    .instagram_activity_boost_info_item:nth-child(2) .instagram_activity_boost_info_item_img img {
        width: 46px!important;

    }
    .instagram_activity_boost_info_item:nth-child(3) .instagram_activity_boost_info_item_img  img{
        width: 37px!important;

    }
    .instagram_activity_boost_info_item_img  {
        height: 49px!important;
        margin-bottom: 15px!important;
        padding-left: unset!important;
    }
    .instagram_activity_boost_title {
        font-size: 45px!important;
        max-width: unset!important;
        line-height: unset!important;
        margin-bottom: 52px!important;
    }
    .instagram_activity_boost_title_btn_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .instagram_activity_boost_info_item_title {
        font-size: 20px!important;
        width: unset!important;
        margin-bottom: 11px!important;
        height: unset!important;
    }
    .instagram_activity_boost_get_started_btn {
        max-width: unset!important;
        font-size: 12px!important;
    }
    .instagram_activity_boost_info_item_text_line {
        display: none!important;
    }
    .instagram_activity_boost_info_item_text {
        max-width: unset!important;
    }
    .instagram_activity_boost_info_items_wrapper {
        flex-direction: column;
        max-width: unset!important;
    }
    .instagram_activity_boost_info_item {
        max-width: unset!important;
        margin-bottom: 24px;
        width: 100% !important;
    }

}
@media  (max-width: 330px) {
    .instagram_activity_boost_get_started_btn {
        margin-bottom: 31px!important;
    }
    .instagram_activity_boost_info_item:first-child .instagram_activity_boost_info_item_img img {
        width: 49px!important;

    }
    .instagram_activity_boost_info_item:nth-child(2) .instagram_activity_boost_info_item_img img {
        width: 46px!important;

    }
    .instagram_activity_boost_info_item:nth-child(3) .instagram_activity_boost_info_item_img  img{
        width: 37px!important;

    }
    .instagram_activity_boost_info_item_img  {
        height: 49px!important;
        margin-bottom: 15px!important;
        padding-left: unset!important;
    }
    .instagram_activity_boost_title {
        font-size: 42px!important;
        max-width: unset!important;
        line-height: unset!important;
        margin-bottom: 52px!important;
    }
    .instagram_activity_boost_title_btn_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .instagram_activity_boost_info_item_title {
        font-size: 20px!important;
        width: unset!important;
        margin-bottom: 11px!important;
        height: unset!important;
    }
    .instagram_activity_boost_get_started_btn {
        max-width: unset!important;
        font-size: 12px!important;
    }
    .instagram_activity_boost_info_item_text_line {
        display: none!important;
    }
    .instagram_activity_boost_info_item_text {
        max-width: unset!important;
    }
    .instagram_activity_boost_info_items_wrapper {
        flex-direction: column;
        max-width: unset!important;
    }
    .instagram_activity_boost_info_item {
        max-width: unset!important;
        margin-bottom: 24px;
        width: 100% !important;
    }

}

/*instagram activity boost section end*/



/*HOW IT WORKS SECTION START*/

.how_it_works {
    width: 100%;
    margin-bottom: 82px;
}
.how_it_works_wrapper {
    width: 100%;
    max-width: 1303px;
    margin: 0 auto;
}
.how_it_works_title {
    margin-bottom: 81px;
    color: #FFFFFF;
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    letter-spacing: -2px;

}

.how_it_works_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 114px;
}
.how_it_works_item{
    width: 100%;
    max-width: 365px;
    background: #F1F5F9;
    border-radius: 20px;
    padding-top: 28px;
    padding-bottom: 25px;
    padding-left: 22px;
    padding-right: 22px;
    height: 493px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.how_it_works_item_img {
    width: 100%;
    max-width: 323px;
    height: 323px;
    margin-bottom: 21px;
}
.how_it_works_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 18px;
    overflow: hidden;
}

.how_it_works_item_info {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
    color: #000000;
    max-width: 230px;

}
.how_it_works_item_info2 {
    display: block;
    background-color: #93c5fd;
}
.try_it_for_free_block {
    width: 100%;
    background-image: url("../img/back_try_img.png");
    min-height: 370px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    padding-top: 65px;
    padding-bottom: 66px;
    padding-right: 110px;
    padding-left: 110px;
    border: 1px solid #827373;

}


.try_it_for_free_title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    line-height: 62px;
    letter-spacing: -2px;
    color: #FFFFFF;
    margin-bottom: 20px;
}
.try_it_for_free_info {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #FFFFFF;

}
.try_it_for_free_info_box {
    margin-bottom: 44px;
}

.try_it_for_free_btn {
    max-width: 310px;
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #18181B;
    border: none;
}

@media (min-width: 769px) and (max-width: 1440px) {
    .how_it_works_wrapper{
        max-width: unset!important;
        padding-left: 45px;
        padding-right: 45px;
    }

}
@media  (min-width: 381px) and (max-width: 769px) {
    .how_it_works_wrapper{
        max-width: unset!important;
        /*padding-left: 30px;*/
        /*padding-right: 30px;*/
    }
    .how_it_works_title {
        padding-left: 45px!important;
    }
    .try_it_for_free_block {
        width: 90%!important;
        margin: 0 auto;
    }

}
@media  (max-width: 380px) {
    .try_it_for_free_block {
        width: 90%!important;
        margin: 0 auto;
    }
    .how_it_works_wrapper{
        max-width: unset!important;
        /*padding-left: 30px;*/
        /*padding-right: 30px;*/
    }
    .how_it_works_title {
        padding-left: 30px!important;
    }

}

@media (min-width: 769px) and (max-width: 1250px) {
    .how_it_works_item{
        max-width: unset;
        width: 30% !important;
    }

}

@media (min-width: 1076px) and (max-width: 1170px) {
    .try_it_for_free_title {
        font-size: 45px!important;
        line-height: unset!important;
    }
    .how_it_works_items_wrapper {
        margin-bottom: 90px!important;
    }
    .how_it_works_item {
        padding-top: 25px!important;
        padding-left: 18px!important;
        padding-right: 18px!important;
    }
    .how_it_works_item_img {
        height: 290px!important;
    }
    .try_it_for_free_block {
        padding-left: 70px!important;
        padding-right: 70px!important;
        padding-top: 50px!important;
        padding-bottom: 50px!important;
    }
}

@media (min-width: 971px) and (max-width: 1075px) {
    .try_it_for_free_title {
        font-size: 45px!important;
        line-height: unset!important;
    }
    .try_it_for_free_block {
        padding-left: 70px!important;
        padding-right: 70px!important;
        padding-top: 50px!important;
        padding-bottom: 50px!important;
    }
    .how_it_works_items_wrapper {
        margin-bottom: 80px!important;
    }
    .how_it_works_title {
        margin-bottom: 55px!important;
        font-size: 45px!important;
    }
    .how_it_works_item {
        padding-top: 25px!important;
        padding-left: 18px!important;
        padding-right: 18px!important;
        height: 450px!important;
    }
    .how_it_works_item_img {
        height: 270px!important;
    }
}
@media  (min-width: 851px) and (max-width: 971px) {
    .how_it_works {
        margin-bottom: 60px!important;
    }
    .try_it_for_free_title {
        font-size: 40px!important;
        line-height: unset!important;
    }
    .try_it_for_free_block {
        padding-left: 50px!important;
        padding-right: 50px!important;
        padding-top: 40px!important;
        padding-bottom: 40px!important;
    }
    .how_it_works_items_wrapper {
        margin-bottom: 80px!important;
    }
    .how_it_works_title {
        margin-bottom: 40px!important;
        font-size: 40px!important;
    }
    .how_it_works_item {
        padding-top: 25px!important;
        padding-left: 18px!important;
        padding-right: 18px!important;
        height: 400px!important;
    }
    .how_it_works_item_img {
        height: 220px!important;
    }
    .how_it_works_item_info {
        font-size: 19px!important;
        line-height: unset!important;
    }
}
@media (min-width: 769px) and  (max-width: 851px) {
    .how_it_works {
        margin-bottom: 50px!important;
    }
    .try_it_for_free_info {
        font-size: 24px!important;
    }
    .try_it_for_free_title {
        font-size: 38px!important;
        line-height: unset!important;
    }
    .try_it_for_free_block {
        padding-left: 50px!important;
        padding-right: 50px!important;
        padding-top: 40px!important;
        padding-bottom: 40px!important;
    }
    .how_it_works_items_wrapper {
        margin-bottom: 70px!important;
    }
    .how_it_works_title {
        margin-bottom: 40px!important;
        font-size: 36px!important;
    }
    .how_it_works_item {
        padding-top: 25px!important;
        padding-left: 18px!important;
        padding-right: 18px!important;
        height: 350px!important;
    }
    .how_it_works_item_img {
        height: 180px!important;
    }
    .how_it_works_item_info {
        font-size: 16px!important;
        line-height: unset!important;
    }
}
@media  (min-width: 426px) and (max-width: 768px) {
    .how_it_works {
        margin-bottom: 46px!important;
    }
    .try_it_for_free_info {
        font-size: 22px!important;
    }
    .try_it_for_free_title {
        font-size: 34px!important;
        line-height: unset!important;
    }
    .try_it_for_free_block {
        border-radius: 20px!important;
        padding-left: 40px!important;
        padding-right: 40px!important;
        padding-top: 40px!important;
        padding-bottom: 40px!important;
        min-height: unset!important;
    }
    .how_it_works_items_wrapper {
        margin-bottom: 63px!important;
    }
    .how_it_works_title {
        margin-bottom: 20px!important;
        font-size: 34px!important;
    }
    .how_it_works_item {
        padding-top: 22px!important;
        padding-left: 16px!important;
        padding-right: 16px!important;
        height: unset!important;
        width: unset!important;
    }
    .how_it_works_item_img {
        height: unset!important;
    }
    .how_it_works_item_info {
        font-size: 21px!important;
        line-height: unset!important;
    }

    .mobile_how_it_works_items_wrapper {
        display: block!important;
        margin-bottom: 30px;
    }
    .how_it_works_items_wrapper {
        display: none!important;
    }
}
@media   (max-width: 425px) {
    .how_it_works {
        margin-bottom: 46px!important;
    }
    .try_it_for_free_info {
        font-size: 20px!important;
    }
    .try_it_for_free_title {
        font-size: 34px!important;
        line-height: unset!important;
    }
    .try_it_for_free_block {
        border-radius: 10px!important;
        padding-left: 30px!important;
        padding-right: 30px!important;
        padding-top: 40px!important;
        padding-bottom: 40px!important;
        min-height: unset!important;
    }
    .how_it_works_items_wrapper {
        margin-bottom: 63px!important;
    }
    .how_it_works_title {
        margin-bottom: 20px!important;
        font-size: 34px!important;
    }
    .how_it_works_item {
        padding-top: 22px!important;
        padding-left: 16px!important;
        padding-right: 16px!important;
        height: unset!important;
        width: unset!important;
    }
    .how_it_works_item_img {
        height: unset!important;
    }
    .how_it_works_item_info {
        font-size: 21px!important;
        line-height: unset!important;
    }

    .mobile_how_it_works_items_wrapper {
        display: block!important;
        margin-bottom: 30px;
    }
    .how_it_works_items_wrapper {
        display: none!important;
    }
}



.mobile_how_it_works_items_wrapper {
    display: none;
}
/*HOW IT WORKS SECTION END*/

/*Features Start*/
.features {
    width: 100%;
}
.features_wrapper {
    width: 100%;
    max-width: 1137px;
    margin: 0 auto;
}
.features_wrapper_child {
    width: 100%;
    margin-bottom: 96px;
}
.features_wrapper_child_title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    line-height: 62px;
    text-align: center;
    letter-spacing: -2px;
    color: #FFFFFF;
    margin-bottom: 96px;
}
.features_wrapper_child_items_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.features_wrapper_child_item {
    width: 32%;
    background: #18181B;
    border-radius: 6px;
    padding-left: 34px;
    padding-right: 34px;
    padding-top: 31px;
    padding-bottom: 40px;
    height: 598px;
}
.features_wrapper_child_item_parent {
    width: 32%;
    border: 2px solid transparent;
    background: linear-gradient(98.24deg, #6DDCFF 0%, #7F60F9 100%);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: 6px;
    padding: 2px;
    height: 598px;
}
.features_wrapper_child_item_get_started_btn {
    background: #18181B;
    height: 55px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #7F60F9;
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    text-decoration: none;

}



.features_wrapper_child_item_get_started_btn2 {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    background: linear-gradient(98.24deg, #6DDCFF 0%, #7F60F9 100%);
    height: 55px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    text-decoration: none;
}

.features_wrapper_child_item2 {
    background: #18181B;
    padding-left: 34px;
    padding-right: 34px;
    padding-top: 31px;
    padding-bottom: 40px;
    height: 100%;
}

.features_wrapper_child_item_title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 14px;
}

.features_wrapper_child_item_price_info_box {
    display: flex;
    align-items: flex-end;
    margin-bottom: 18px;
}
.features_wrapper_child_item_price_info {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 66px;
    color: #FFFFFF;
}
.features_wrapper_child_item_price_info2 {
    font-family: 'IBM Plex Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    color: #71717A;
}
.features_wrapper_child_item_info2 {
    font-family: 'IBM Plex Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /*line-height: 24px;*/
    color: #A1A1AA;
    margin-bottom: 31px;
}
.features_wrapper_child_item_line {
    border: 1px solid #27272A;
    margin-bottom: 30px;
}
.features_wrapper_child_item_detail {
    display: flex;
    margin-bottom: 20px;
}
.features_wrapper_child_item_detail_info {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    padding-left: 6px;
    padding-right: 6px;
}

@media (min-width: 769px) and (max-width: 1226px) {
    .features_wrapper{
        max-width: unset!important;
        padding-left: 45px;
        padding-right: 45px;
    }
}

@media (min-width: 769px) and (max-width: 1120px) {
    .features_wrapper_child_item {
        padding-left: 20px!important;
        padding-right: 20px!important;
    }

    .features_wrapper_child_item2 {
        padding-left: 20px!important;
        padding-right: 20px!important;
    }

}
@media  (max-width: 768px) {
    .features_wrapper_child_item {
        padding-left: 20px!important;
        padding-right: 20px!important;
        padding-top: 20px!important;
        padding-bottom: 25px!important;
    }

    .features_wrapper_child_item2 {
        padding-left: 20px!important;
        padding-right: 20px!important;
        padding-top: 20px!important;
        padding-bottom: 25px!important;
    }

}

@media (min-width: 852px) and (max-width: 1024px) {
    .features_wrapper_child_title {
        margin-bottom: 75px!important;
        font-size: 48px!important;
    }
    .features_wrapper_child {
        margin-bottom: 75px!important;
    }
    .features_wrapper_child_item_detail_info {
        font-size: 14px!important;
    }
    .features_wrapper_child_item_info2 {
        font-size: 14px!important;
        margin-bottom: 25px!important;
    }
    .features_wrapper_child_item_line {
        margin-bottom: 25px!important;
    }
    .features_wrapper_child_item_price_info {
        font-size: 50px!important;
        line-height: 55px!important;
    }
    .features_wrapper_child_item {
        height: 550px!important;
    }
    .features_wrapper_child_item_parent {
        height: 550px!important;
    }


}
@media (min-width: 769px) and  (max-width: 851px) {
    .features_wrapper_child_title {
        margin-bottom: 65px!important;
        font-size: 45px!important;
    }
    .features_wrapper_child {
        margin-bottom: 61px!important;
    }
    .features_wrapper_child_item_detail_info {
        font-size: 12px!important;
    }
    .features_wrapper_child_item_info2 {
        font-size: 12px!important;
        margin-bottom: 23px!important;
    }
    .features_wrapper_child_item_line {
        margin-bottom: 25px!important;
    }
    .features_wrapper_child_item_price_info {
        font-size: 45px!important;
        line-height: 50px!important;
    }
    .features_wrapper_child_item {
        height: 470px!important;
    }
    .features_wrapper_child_item_parent {
        height: 470px!important;
    }
.features_wrapper_child_item_detail {
    margin-bottom: 10px!important;
}
 .features_wrapper_child_item_get_started_btn2 {
     height: 45px!important;
     font-size: 14px!important;
 }
 .features_wrapper_child_item_get_started_btn{
     font-size: 14px!important;
     height: 45px!important;
 }
}
@media (min-width: 426px) and (max-width: 768px) {
    .features_wrapper_child_item_title {
        margin-bottom: 8px!important;
    }
    .features_wrapper_child_title {
        margin-bottom: 55px!important;
        font-size: 40px!important;
        text-align: unset!important;
    }
    .features_wrapper_child {
        margin-bottom: 51px!important;
    }
    .features_wrapper_child_item_detail_info {
        font-size: 12px!important;
    }
    .features_wrapper_child_item_info2 {
        font-size: 12px!important;
        margin-bottom: 22px!important;
    }
    .features_wrapper_child_item_line {
        margin-bottom: 25px!important;
    }
    .features_wrapper_child_item {
        height: 470px!important;
    }
    .features_wrapper_child_item_parent {
        height: 470px!important;
    }
.features_wrapper_child_item_detail {
    margin-bottom: 14px!important;
}
 .features_wrapper_child_item_get_started_btn2 {
     height: 45px!important;
     font-size: 14px!important;
 }
 .features_wrapper_child_item_get_started_btn{
     font-size: 14px!important;
     height: 45px!important;
 }
}
@media  (max-width: 425px) {
    .features_wrapper_child_item_price_info_box {
        margin-bottom: 10px!important;
    }
    .features_wrapper_child_item_price_info2 {
        display: none!important;
    }
    .features_wrapper_child_item_title {
        margin-bottom: 8px!important;
    }
    .features_wrapper_child_title {
        margin-bottom: 46px!important;
        font-size: 34px!important;
        text-align: unset!important;
    }
    .features_wrapper_child {
        margin-bottom: 51px!important;
    }
    .features_wrapper_child_item_detail_info {
        font-size: 10px!important;
    }
    .features_wrapper_child_item_info2 {
        font-size: 12px!important;
        margin-bottom: 22px!important;
    }
    .features_wrapper_child_item_line {
        margin-bottom: 25px!important;
    }
    .features_wrapper_child_item {
        height: 450px!important;
    }
    .features_wrapper_child_item_parent {
        height: 450px!important;
    }
.features_wrapper_child_item_detail {
    margin-bottom: 15px!important;
}
 .features_wrapper_child_item_get_started_btn2 {
     height: 36px!important;
     font-size: 12px!important;
 }
 .features_wrapper_child_item_get_started_btn{
     font-size: 12px!important;
     height: 36px!important;
 }
}

.mobile_features_wrapper_child_items_wrapper {
    display: none;
}
@media (max-width: 768px) {
    .mobile_features_wrapper_child_items_wrapper{
        display: flex!important;
    }
    .features_wrapper_child_items_wrapper {
        display: none!important;
    }
    .features_wrapper_child_item {
        width: unset!important;
    }
    .features_wrapper_child_item_parent {
        width: unset!important;
    }
    .features_wrapper{
        max-width: unset!important;
    }

}

@media (min-width: 381px) and (max-width: 768px) {
    .features_wrapper_child_title {
        padding-left: 45px;
    }
}
@media (max-width: 380px) {
    .features_wrapper_child_title {
        padding-left: 30px;
    }
}
/*Features End*/

/*FAQ start*/
.faq{
    width: 100%;
    margin-bottom: 120px;
}
.faq_wrapper {
    width: 100%;
    max-width: 1137px;
    margin: 0 auto;
}
.faq_title {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    text-align: center;
    letter-spacing: -2px;
    color: #FFFFFF;
    margin-bottom: 73px;

}
.faq_line {
     width: 100%;
    height: 1px;
    background: linear-gradient(98.24deg, #6DDCFF 0%, #7F60F9 100%);
    margin-bottom: 43px;

}
.faq_item_title {
    font-family: 'IBM Plex Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    color: #FFFFFF;

}
.faq_item_hidden_info {
    font-family: 'IBM Plex Sans',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: #D4D4D8;

}
.faq_item_hidden_info_box {
    width: 100%;
    margin-bottom: 34px;
    /*display: none;*/
}
.faq_item_btn {
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.faq_item {
    width: 100%;
    margin-bottom: 38px;
    border-bottom: 1px solid #71717A;
}
.faq_item_title_icon_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 43px;
}
@media (min-width: 381px) and  (max-width: 1226px) {
    .faq{
        max-width: unset!important;
        padding-left: 45px;
        padding-right: 45px;
    }
}
@media   (max-width: 380px) {
    .faq{
        max-width: unset!important;
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media (min-width: 852px) and (max-width: 1024px) {
    .faq_title {
        font-size: 48px!important;
    }
    .faq_item_title {
        font-size: 30px!important;
    }
    .faq_item_hidden_info {
        font-size: 25px!important;
    }
}
@media (min-width: 769px) and (max-width: 851px) {
    .faq_item_title {
        font-size: 25px!important;
    }
    .faq_title {
        font-size: 45px!important;
    }
    .faq_item_hidden_info {
        font-size: 22px!important;
    }
}
@media (min-width: 426px) and (max-width: 768px) {
    .faq_item_hidden_info {
        font-size: 18px!important;
    }
    .faq_line {
        margin-bottom: 35px!important;
    }
    .faq_item_title_icon_wrapper {
        margin-bottom: 33px!important;
    }
    .faq_item_btn img {
        width: 30px!important;
    }
    .faq_item_title {
        font-size: 20px!important;
    }
    .faq_title {
        font-size: 40px!important;
        margin-bottom: 65px!important;
        text-align: left!important;
    }
}
@media (min-width: 376px) and (max-width: 425px) {
    .faq_item_hidden_info {
        font-size: 16px!important;
    }
    .faq_line {
        margin-bottom: 25px!important;
    }
    .faq_item_title_icon_wrapper {
        margin-bottom: 25px!important;
    }
    .faq_item_btn img {
        width: 30px!important;
    }
    .faq_item_title {
        font-size: 16px!important;
    }
    .faq_title {
        font-size: 34px!important;
        margin-bottom: 60px!important;
        text-align: left!important;
    }
}
@media  (max-width: 375px) {
    .faq_item_hidden_info {
        font-size: 14px!important;
    }
    .faq_line {
        margin-bottom: 25px!important;
    }
    .faq_item_title_icon_wrapper {
        margin-bottom: 25px!important;
    }
    .faq_item_btn img {
        width: 26px!important;
    }
    .faq_item_title {
        font-size: 14px!important;
    }
    .faq_title {
        font-size: 32px!important;
        margin-bottom: 55px!important;
        text-align: left!important;
    }
}

/*FAQ end
*/


@media (min-width: 871px) and (max-width: 1024px) {
    .instagram_activity_boost {
        margin-top: 95px!important;
    }
}
@media (min-width: 769px) and (max-width: 871px) {
    .instagram_activity_boost {
        margin-top: 90px!important;
    }
}
@media  (max-width: 768px) {
    .instagram_activity_boost {
        margin-top: 80px!important;
    }
}


/*!*popup start*!*/
/*.show_select_account_popup {*/
/*    !*display: none;*!*/
/*    position: fixed; !* Stay in place *!*/
/*    z-index: 1; !* Sit on top *!*/
/*    left: 0;*/
/*    !*right: -1000px;*!*/
/*    !*transition: 0.5s;*!*/
/*    top: 0;*/
/*    width: 100%; !* Full width *!*/
/*    height: 100%; !* Full height *!*/
/*    overflow: auto; !* Enable scroll if needed *!*/
/*    background:  rgba(0, 0, 0, 0.7);*/
/*    padding-top: 100px;*/
/*    padding-bottom: 100px;*/
/*}*/
/*.show_select_account_popup_wrapper {*/
/*    position: relative;*/
/*    width: 100%;*/
/*    max-width: 693px;*/
/*    margin: 0 auto;*/
/*    background-image: url("../img/select_post_back_img.png");*/
/*    background-size: cover;*/
/*    background-repeat: no-repeat;*/
/*    padding-top: 65px;*/
/*    padding-bottom: 92px;*/
/*    padding-left: 98px;*/
/*    padding-right: 98px;*/
/*    border-radius: 30px;*/
/*    box-shadow: 8px 4px 78px rgba(0, 0, 0, 0.25);*/
/*    border: 1px solid #CEC7C7;*/
/*}*/

/*.show_select_account_popup_title {*/
/*    font-family: 'Inter',sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-size: 50px;*/
/*    text-align: center;*/
/*    color: #FFFFFF;*/
/*    margin-bottom: 74px;*/
/*}*/
/*.show_select_account_popup_input_field_wrapper {*/
/*    width: 100%;*/
/*    background: #FFFFFF;*/
/*    border: 1px solid #E4E4E7;*/
/*    border-radius: 9px;*/
/*    height: 65px;*/
/*    margin-bottom: 21px;*/
/*    padding: 15px;*/
/*    display: flex;*/
/*    !*align-items: center;*!*/

/*}*/


/*.show_select_account_popup_textarea_field {*/
/*    font-family: 'Poppins',sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 16px;*/
/*    line-height: 26px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    text-align: center;*/
/*     color: #52525B;*/
/*    width: 100%;*/
/*    border: none;*/
/*    background: none;*/
/*    height: 100%;*/
/*    resize: none;*/
/*    cursor: pointer;*/
/*    outline: none;*/

/*}*/

/*.show_select_account_popup_input_field {*/
/*    font-family: 'Inter',sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 700;*/
/*    font-size: 18px;*/
/*    color: #090A0A;*/
/*    height: 100%;*/
/*    border: none;*/
/*    cursor: pointer;*/
/*    outline: none;*/
/*    width: 75%;*/
/*}*/
/*.show_select_account_popup_input_field::placeholder {*/
/*    font-family: 'Inter',sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 15px;*/
/*    color: #000000;*/
/*}*/

/*.show_select_account_popup_continue_btn {*/
/*    width: 100%;*/
/*    background: #2563EB;*/
/*    border-radius: 9px;*/
/*    height: 65px;*/
/*    border: none;*/
/*    cursor: pointer;*/
/*    outline: none;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    margin-top: 40px;*/
/*    font-family: 'Poppins',sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    font-size: 16px;*/
/*    letter-spacing: -0.2px;*/
/*    color: #FFFFFF;*/

/*}*/
/*.show_select_account_popup_close_btn {*/
/*    cursor: pointer;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    background: none;*/
/*    border: 1px #6DDCFF solid;*/
/*    border-radius: 60px;*/
/*    width: 46px;*/
/*    height: 36px;*/
/*    position: absolute;*/
/*    right: 45px;*/
/*    top: 25px;*/
/*}*/
/*.show_select_account_popup_input_field_icon2 {*/
/*    margin-right: 10px;*/
/*    height: 33px;*/
/*}*/

/*@media (min-width: 480px) and (max-width: 768px) {*/
/*    .show_select_pay_popup_input_field {*/
/*        height: 54px!important;*/
/*    }*/
/*    .select_pay_item {*/
/*        padding-left: 35px!important;*/
/*    }*/
/*    .select_pay_item_icon1 img {*/
/*        width: 23px!important;*/
/*    }*/
/*    .select_pay_item_icon2 img {*/
/*        width: 23px!important;*/
/*    }*/
/*    .select_pay_item {*/
/*        height: 54px!important;*/
/*    }*/
/*    .show_select_account_popup_wrapper {*/
/*        max-width: unset!important;*/
/*        width: 90% !important;*/
/*        padding-left: 50px!important;*/
/*        padding-right: 50px!important;*/
/*    }*/
/*    .show_select_account_popup_title {*/
/*        font-size: 45px!important;*/
/*        margin-bottom: 50px!important;*/
/*    }*/
/*    .show_select_account_popup_close_btn {*/
/*        width: 35px!important;*/
/*        height: 26px!important;*/
/*        position: absolute;*/
/*        right: 20px!important;*/
/*        top: 20px!important;*/
/*    }*/
/*    .show_select_account_popup_close_btn img {*/
/*        width: 12px!important;*/
/*        height: 12px!important;*/
/*    }*/
/*}*/
/*@media (min-width: 426px) and  (max-width: 480px) {*/
/*    .show_select_pay_popup_input_field {*/
/*        height: 54px!important;*/
/*    }*/
/*    .select_pay_item {*/
/*        padding-left: 25px!important;*/
/*    }*/
/*    .select_pay_item_icon1 img {*/
/*        width: 20px!important;*/
/*    }*/
/*    .select_pay_item_icon1 {*/
/*        margin-right: 10px!important;*/
/*    }*/
/*    .select_pay_item_icon2 img {*/
/*        width: 23px!important;*/
/*    }*/
/*    .select_pay_item {*/
/*        height: 44px!important;*/
/*    }*/
/*    .show_select_account_popup_wrapper {*/
/*        max-width: unset!important;*/
/*        width: 90% !important;*/
/*        padding-left: 30px!important;*/
/*        padding-right: 30px!important;*/
/*    }*/
/*    .show_select_account_popup_title {*/
/*        font-size: 40px!important;*/
/*        margin-bottom: 33px!important;*/
/*    }*/
/*    .show_select_account_popup_close_btn {*/
/*        width: 35px!important;*/
/*        height: 26px!important;*/
/*        position: absolute;*/
/*        right: 20px!important;*/
/*        top: 20px!important;*/
/*    }*/
/*    .show_select_account_popup_close_btn img {*/
/*        width: 12px!important;*/
/*        height: 12px!important;*/
/*    }*/
/*    .show_select_account_popup_input_field_wrapper {*/
/*        height: 60px!important;*/
/*    }*/
/*    .show_select_account_popup_continue_btn {*/
/*        height: 60px!important;*/
/*    }*/
/*}*/
/*@media  (max-width: 425px) {*/
/*    .show_select_pay_popup_input_field {*/
/*        height: 54px!important;*/
/*    }*/
/*    .select_pay_item {*/
/*        padding-left: 25px!important;*/
/*    }*/
/*    .select_pay_item_icon1 {*/
/*        margin-right: 10px!important;*/
/*    }*/
/*    .select_pay_item_icon2 img {*/
/*        width: 23px!important;*/
/*    }*/
/*    .select_pay_item_icon1 img {*/
/*        width: 18px!important;*/
/*    }*/
/*    .select_pay_item {*/
/*        height: 44px!important;*/
/*    }*/
/*    .show_select_account_popup_input_field_icon img {*/
/*        width: 37px!important;*/
/*    }*/
/*    .show_select_account_popup{*/
/*        padding-top: 50px!important;*/
/*        padding-bottom: 50px!important;*/
/*    }*/
/*    .show_select_account_popup_wrapper {*/
/*        max-width: unset!important;*/
/*        width: 90% !important;*/
/*        padding-left: 20px!important;*/
/*        padding-right: 20px!important;*/
/*    }*/
/*    .show_select_account_popup_title {*/
/*        font-size: 40px!important;*/
/*        margin-bottom: 23px!important;*/
/*        text-align: left!important;*/
/*    }*/
/*    .show_select_account_popup_close_btn {*/
/*        width: 35px!important;*/
/*        height: 26px!important;*/
/*        position: absolute;*/
/*        right: 20px!important;*/
/*        top: 20px!important;*/
/*    }*/
/*    .show_select_account_popup_close_btn img {*/
/*        width: 12px!important;*/
/*        height: 12px!important;*/
/*    }*/
/*    .show_select_account_popup_input_field_wrapper {*/
/*        height: 55px!important;*/
/*    }*/
/*    .show_select_account_popup_continue_btn {*/
/*        height: 55px!important;*/
/*    }*/
/*    .show_select_account_popup_input_field_icon2 img {*/
/*        width: 25px!important;*/
/*    }*/
/*    .show_select_account_popup_input_field::placeholder {*/
/*        font-size: 15px!important;*/
/*    }*/
/*    .show_select_account_popup_textarea_field {*/
/*        font-size: 15px!important;*/
/*    }*/
/*    .show_select_account_popup_input_field {*/
/*        font-size: 15px!important;*/
/*    }*/

/*}*/

/*.select_posts_items_wrapper {*/
/*    width: 100%;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    flex-wrap: wrap;*/
/*}*/
/*.select_posts_item {*/
/*    width: 32%;*/
/*    height: 148px;*/
/*    margin-bottom: 13px;*/
/*    border: none;*/
/*    background: none;*/
/*    cursor: pointer;*/
/*    !*border: 4px solid #485AFF;*!*/
/*    !*border: 4px solid transparent;*!*/
/*    border-radius: 13px;*/

/*}*/
/*.select_posts_item img {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    object-fit: cover;*/
/*    border-radius: 13px;*/
/*    overflow: hidden;*/
/*}*/

/*@media (min-width: 476px) and (max-width: 768px) {*/
/*    .select_posts_item {*/
/*        height: 100px!important;*/
/*    }*/
/*    .show_select_account_popup_wrapper {*/
/*        padding-bottom: 70px!important;*/
/*    }*/
/*    .select_posts_item {*/
/*        margin-bottom: 10px!important;*/
/*    }*/
/*    .select_img {*/
/*        height: 420px!important;*/
/*    }*/
/*}*/
/*@media (min-width: 426px) and (max-width: 475px) {*/
/*    .select_img {*/
/*        height: 380px!important;*/
/*        margin-bottom: 23px!important;*/
/*    }*/
/*    .select_posts_item {*/
/*        margin-bottom: 8px!important;*/
/*    }*/
/*    .show_select_account_popup_wrapper {*/
/*        padding-bottom: 50px!important;*/
/*    }*/
/*    .select_posts_item {*/
/*        height: 94px!important;*/
/*    }*/
/*}*/
/*@media  (max-width: 425px) {*/
/*    .select_img {*/
/*        height: unset!important;*/
/*        margin-bottom: 13px!important;*/
/*    }*/
/*    .show_select_account_popup_continue_btn {*/
/*        margin-top: 30px!important;*/
/*    }*/
/*    .select_posts_item {*/
/*        margin-bottom: 8px!important;*/
/*    }*/
/*    .show_select_account_popup_wrapper {*/
/*        padding-bottom: 50px!important;*/
/*    }*/
/*    .select_posts_item {*/
/*        height: unset!important;*/
/*    }*/
/*}*/

/*.select_themes_item {*/
/*    !*width: 74px;*!*/
/*    !*height: 28px;*!*/
/*    border-radius: 60px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    cursor: pointer;*/
/*    outline: none;*/
/*    border: 1px solid #6DDCFF;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 14px;*/
/*    color: #FFFFFF;*/
/*    margin-bottom: 9px;*/
/*    background: none;*/
/*    padding-left: 12px;*/
/*    padding-right: 12px;*/
/*    padding-top: 6px;*/
/*    padding-bottom: 6px;*/

/*}*/
/*.select_img {*/
/*    width: 100%;*/
/*    max-width: 472px;*/
/*    height: 472px;*/
/*    margin: 0 auto;*/
/*    margin-bottom: 33px;*/
/*}*/
/*.select_img img {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    object-fit: cover;*/
/*    border-radius: 13px;*/
/*    overflow: hidden;*/
/*}*/
/*.select_pay_item {*/
/*    max-width: 472px;*/
/*    width: 100%;*/
/*    height: 64px;*/
/*    border-radius: 60px;*/
/*    margin: 0 auto;*/
/*    margin-bottom: 17px;*/
/*    border: 1px solid #6DDCFF;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    background: none;*/
/*    cursor: pointer;*/
/*    outline: none;*/
/*    padding-left: 47px;*/
/*    padding-right: 15px;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 14px;*/
/*    color: #FFFFFF;*/

/*}*/
/*.select_pay_item_icons_wrapper {*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/
/*.show_select_pay_popup_continue_btn {*/
/*    width: 100%;*/
/*    background: none;*/
/*    border-radius: 9px;*/
/*    height: 65px;*/
/*    cursor: pointer;*/
/*    outline: none;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    margin-top: 17px;*/
/*    font-family: 'Poppins',sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 600;*/
/*    font-size: 16px;*/
/*    letter-spacing: -0.2px;*/
/*    color: #FFFFFF;*/
/*    border: 2px solid #2563EB;*/
/*    border-radius: 9px;*/
/*}*/

/*.show_select_pay_popup_input_field {*/
/*    width: 100%;*/
/*    cursor: pointer;*/
/*    outline: none;*/
/*    background: #FFFFFF;*/
/*    border-radius: 100px;*/
/*    height: 64px;*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 15px;*/
/*    letter-spacing: -0.24px;*/
/*    color: #000000;*/
/*    padding-left: 40px*/
/*}*/

/*.show_select_pay_popup_input_field_parent {*/
/*    width: 100%*/
/*}*/
/*.show_select_pay_popup_input_field::placeholder {*/
/*    font-family: 'Inter', sans-serif;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    font-size: 15px;*/
/*    letter-spacing: -0.24px;*/
/*    color: #000000;*/
/*}*/

/*.select_pay_item_icon1 {*/
/*    margin-right: 15px;*/
/*}*/
/*!*popup end*!*/

